<template>
  <div>
    <v-breadcrumbs :items="breadcrumbItems"/>

    <v-card  v-if="event">
      <Header :event="event" />

      <div v-if="event">
        <v-alert tile v-if="!event.published" type="info">This event is not yet published. An event must be published before it can be used or viewed.</v-alert>
        <v-alert tile v-if="!event.verification" type="warning">This event does not require verification codes, anyone can join. Click Edit to change this setting.</v-alert>
        <v-card-title class="subtitle pt-4">Manage verification codes</v-card-title>
        <v-card-text>      
          <p>
            <router-link v-if="event.verification" :to="{name:'eventmanagerBlocklist', params: {id: event.id}}">Manage the blocklist</router-link>
            with all codes that have been used (and optionally release codes).
          </p>

          <p>
            Please enter the list of valid verification codes in this field. 
          </p>
          <ul>
            <li>One code per line please</li>
            <li>Codes are case insensitive. E.g. 'SeCreT' is the same as 'secret'</li>
            <li>Codes are personal and can be used only once</li>
            <li>End a line with a '+' sign (without the quotes) to make that code reusable (useful for private testing)</li>
          </ul>
        </v-card-text>
          
        <v-form ref="form" class="mx-4">
            <v-textarea 
                v-model="codes" 
                class="mb-4"
                label="Event wide verification codes" 
                auto-grow
                persistent-hint
                hint="These codes allow access to any race in this event."
                >
            </v-textarea>

            <v-snackbar v-model="savedSnackbar" top color="success" :timeout="6000">Hooray, the allowlist is updated!</v-snackbar>
            <v-btn color="primary" :loading="$store.getters.isLoading" @click="save">Save codes</v-btn>
        </v-form>


        <v-data-table
          :headers="headers"
          :items="races"
          v-if="races && races.length"
          hide-default-footer
        >
          <template v-slot:top>
            <v-toolbar flat color="white" class="mx-4">
              <v-toolbar-title class="ma-0 mt-8 subtitle">Race specific verification codes</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-dialog v-model="raceDialog" max-width="500px">
                <v-card>
                  <v-card-title>
                    <span class="headline">{{ editedItem.name }} allowlist</span>
                  </v-card-title>
                  <v-card-text>
                    <p>Enter verification codes that grant access only to this specific race.</p>
                    <p>Use <a href="https://onlinenumbertools.com/generate-integers" target="_blank">this (external) tool</a> to quickly generate a sequence of numbers to paste in.</p>
                    <v-textarea 
                        v-model="editedItem.codes" 
                        label="Personal verification codes" 
                        auto-grow
                        >
                    </v-textarea>
                  </v-card-text>

                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="closeRaceDialog">Cancel</v-btn>
                    <v-btn color="blue darken-1" text @click="saveRace">Save</v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-toolbar>
          </template>
          <template v-slot:item.actions="{ item }">
            <v-btn text class="mr-4" @click="editRace(item)">
              <v-icon small class="mr-2">fa-pencil-alt</v-icon>
              Edit
            </v-btn>
          </template>
        </v-data-table>
        <br/>
      </div>      
    </v-card>
  </div>      
</template>

<script>
import { mapGetters } from "vuex";
import eventManagerService from "@/services/eventManagerService";
import { EventBus } from '@/plugins/eventbus.js';
import siteData from '@/data/site.json'
import Header from './_Header.vue'

export default {
  name: "Profile",
  components: {
    Header,
  },
  props: {
  },
  data() {
    return {
      event: null,
      codes: null,
      savedSnackbar: false,
      raceDialog: false,
      races: [],
      headers: [
        { text: 'Name', align: 'start', sortable: true, value: 'name',},
        { text: 'Actions', value: 'actions', sortable: false },
      ],
      editedIndex: -1,
      editedItem: {
        codes: '',
      },
      defaultItem: {
        codes: '',
      },
    };
  },
  async mounted() {
    await this.getProfile();
    
    EventBus.$on('login-state-change', async user => {
      await this.getProfile();
    });
  },
  methods: {
    async getEvent(id) {
      var response = await eventManagerService.get(id);
      this.event = response.data;

      this.codes = (await eventManagerService.getAllowlist(id)).data.msg;
      this.races = this.event.races;//.filter(x => x.team);
      console.log('Team races', this.event.races, this.races);
      for (const race of this.races) {
        race.codes = (await eventManagerService.getAllowlist(id, race.id)).data.msg;
      }
    },

    async getProfile() {
      if (this.user) {
        await this.getEvent(this.$route.params.id);
      }
    },

    async save() {
      console.log('Saving');
      var response = (await eventManagerService.putAllowlist(this.event.id, /*raceId:*/ null, this.codes)).data;
      console.log('Saved', response);
      if (response.status == "OK") {
        for (const race of this.races) {
          const raceResponse = (await eventManagerService.putAllowlist(this.event.id, race.id, race.codes)).data;
        }
        
        this.savedSnackbar = true;
        this.codes = response.msg;
      }
    },

    
    closeRaceDialog () {
      this.raceDialog = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },

    editRace(item) {
      this.editedIndex = this.races.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.raceDialog = true
    },
    
    saveRace() {
      if (this.editedIndex > -1) {
        Object.assign(this.races[this.editedIndex], this.editedItem)
      } 
      console.log('saved', this.races);
      this.save();
      this.closeRaceDialog();
    },
  },
  computed: {
    breadcrumbItems() {
      return !this.event ? [] : [
        { text: 'Event Manager', exact: true, to: { name: 'eventmanager' } },
        { text: this.event.name, exact: true, to: { name: 'eventmanagerView', params: {id: this.event.id}} },
        { text: 'Allowlist', disabled: true },
      ];
    },

    ...mapGetters({
      user: "user"
    })
  },
};
</script>
<style lang="scss">
</style>

